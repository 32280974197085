import React, { useContext } from "react";
import { QuestionSection, RadioRange } from "../../components/utility/quote";
import { navigate } from "gatsby-link";
import RadioCircle from "../../svgs/radioCircle";
import SecurityContext from "../../contexts/securityContext";

const Next = "/cyber-security/Questions/3";
const Points = [
  {
    question: "	How many users do you have?",
    progress: "60%",
    options: [
      {
        answer: "1-5",
        to: Next,
        icon: <RadioCircle />,
      },
      {
        answer: "6-10",
        to: Next,
        icon: <RadioCircle />,
      },
      {
        answer: "11-25",
        to: Next,
        icon: <RadioCircle />,
      },
      {
        answer: "26-49",
        to: Next,
        icon: <RadioCircle />,
      },
      {
        answer: "50-100",
        to: Next,
        icon: <RadioCircle />,
      },
      {
        answer: "100+",
        to: Next,
        icon: <RadioCircle />,
      },
    ],
  },
];

export default function Question2({ title }) {
  const { que2, setQue2 } = useContext(SecurityContext);

  return Points.map((item) => {
    return (
      <QuestionSection
        title={title}
        question={item.question}
        progress={item.progress}
      >
        {item.options.map((items) => {
          return (
            <RadioRange
              key={items.answer}
              icon={items.icon}
              value={items.answer}
              title={items.answer}
              isSelected={que2 === items.answer}
              onChange={(e) => {
                setQue2(e.target.value);
                navigate(items.to);
              }}
            />
          );
        })}
      </QuestionSection>
    );
  });
}
