import { createContext } from "react";
const initialstate = {
  que1: "",
  que2: "",
  que3: "",
  que4: "",
};
const SecurityContext = createContext(initialstate);
SecurityContext.displayName = "SecurityContext";

export default SecurityContext;
